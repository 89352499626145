import {
    SUPPORTED_CURRENCIES,
    DEVELOPMENT_LANGUAGES,
    SUPPORTED_REGIONS,
    type Currency,
    type Language,
    type Region,
} from '@/config/localization';

interface AuthenticationSettings {
    // Disables the registration and login UI and everything else which is connected to authentication
    disableAuthentication: boolean;
}

function defaultAuthSettings(): AuthenticationSettings {
    return {
        disableAuthentication: false,
    };
}

interface TutorialSettings {
    // Disables the search form tour
    disableSearchFormTour: boolean;
}

function defaultTutorialSettings(): TutorialSettings {
    return {
        disableSearchFormTour: false,
    };
}

interface BookingSettings {
    // Instead of booking, users can reserve a bus. They receive an email with a link where they can complete the booking
    enableReservation: boolean;
    disableDriverRoomsChoice: boolean;
}

function defaultBookingSettings(): BookingSettings {
    return {
        enableReservation: false,
        disableDriverRoomsChoice: false,
    };
}

interface SearchResultsSettings {
    // Completely disables the result filter UI. Results are always sorted in the `recommended` order
    disableResultFilter: boolean;
    // Disables trip companies
    disableTripCompanies: boolean;
    // The user is required to enter their name before they can see any results
    requireCustomerName: boolean;
    // The user is required to enter their email before they can see any results
    requireCustomerMail: boolean;
    // Shows prices for school; Adds `I am a school` checkbox in the search form to see prices for schools
    // Still requires the user to be a verified school or a reseller
    applicableSchool: boolean;
    // Whether to ignore the usual limitation of maximal arrival and return kilometers for buses on this integration.
    // This setting is handled in the backend.
    ignoreArrivalAndReturnKm: boolean;
    // Whether we automatically send an email to the customer if he searched for buses but did not book within 24 hours
    // This setting is handled in the backend.
    sendSearchResultsReminderMail: boolean;
}

function defaultSearchResultsSettings(): SearchResultsSettings {
    return {
        disableResultFilter: false,
        disableTripCompanies: false,
        requireCustomerName: false,
        requireCustomerMail: false,
        applicableSchool: false,
        ignoreArrivalAndReturnKm: false,
        sendSearchResultsReminderMail: false,
    };
}

interface LocalizationSettings {
    // Disables the language switcher UI
    disableLanguageSwitcher: boolean;
    // Disables the region switcher UI
    disableRegionSwitcher: boolean;
    // Disables the currency switcher UI
    disableCurrencySwitcher: boolean;
    // Optional default language.
    // If no default language is set and the user hasn't selected a language, we detect the language from the browser.
    defaultLanguage: Language | null;
    // Optional default region.
    // If no default region is set and the user hasn't selected a region, we detect the region from the browser.
    defaultRegion: Region | null;
    // Optional default currency.
    defaultCurrency: Currency | null;
}

function defaultLocalizationSettings(): LocalizationSettings {
    return {
        disableLanguageSwitcher: false,
        disableRegionSwitcher: false,
        disableCurrencySwitcher: false,
        defaultLanguage: null,
        defaultRegion: null,
        defaultCurrency: null,
    };
}

interface BrandingSettings {
    // Disables the BusFinder Logo
    disableLogo: boolean;
    // Disable Cookie Banner. We still track the user, if GA cookies are detected
    disableCookieBanner: boolean;
}

function defaultBrandingSettings(): BrandingSettings {
    return {
        disableLogo: false,
        disableCookieBanner: false,
    };
}

function isInOrNull(value: string | null | undefined, values: readonly string[]): string | null {
    return value && values.includes(value) ? value : null;
}

export class AppSettings {
    public static default(): AppSettings {
        return new AppSettings({
            authentication: defaultAuthSettings(),
            tutorial: defaultTutorialSettings(),
            booking: defaultBookingSettings(),
            searchResults: defaultSearchResultsSettings(),
            localization: defaultLocalizationSettings(),
            branding: defaultBrandingSettings(),
        });
    }

    public readonly authentication: AuthenticationSettings;

    public readonly tutorial: TutorialSettings;

    public readonly booking: BookingSettings;

    public readonly searchResults: SearchResultsSettings;

    public readonly localization: LocalizationSettings;

    public readonly branding: BrandingSettings;

    constructor(json: Record<string, any> = {}) {
        this.authentication = json.authentication ?? defaultAuthSettings();
        this.tutorial = json.tutorial ?? defaultTutorialSettings();
        this.booking = json.booking ?? defaultBookingSettings();
        this.searchResults = json.searchResults ?? defaultSearchResultsSettings();

        const localization = json.localization ?? defaultLocalizationSettings();

        this.localization = {
            ...localization,
            defaultLanguage: isInOrNull(localization.defaultLanguage, DEVELOPMENT_LANGUAGES),
            defaultRegion: isInOrNull(localization.defaultRegion, SUPPORTED_REGIONS),
            defaultCurrency: isInOrNull(localization.defaultCurrency, SUPPORTED_CURRENCIES),
        };

        this.branding = json.branding ?? defaultBrandingSettings();
    }
}
